<template>
  <div>
    <el-col :span="12">
      <el-input @keyup.enter.native="getList()" v-model="companyName">
        <template #prepend>公司名称</template>
        <template #append>
          <el-button icon="el-icon-search" @click="getList"></el-button>
        </template>
      </el-input>
    </el-col>
    <el-table :data="tableData" v-loading="tableLoading" @row-dblclick="row=>$refs.editDialog.edit(row)">
      <el-table-column label="公司名称" prop="companyName"></el-table-column>
      <el-table-column label="电话" prop="phone" width="120"></el-table-column>
      <el-table-column label="联系人" prop="contactName" width="80"></el-table-column>
      <!-- <el-table-column label="省" prop="province"></el-table-column>
      <el-table-column label="市" prop="city"></el-table-column> -->
      <el-table-column label="地址" prop="address"></el-table-column>
      <!-- <el-table-column label="是否审核" prop="isAudit"></el-table-column> -->
      <!-- <el-table-column label="创建时间" prop="insertTime"></el-table-column> -->
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import EditDialog from "../Agent/EditDialog.vue";
export default {
  components: { EditDialog },
  data() {
    return {
      tableData: null,
      pageSize: 10,
      currentPage: 1,
      total: 0,
      companyName: "",
      tableLoading: false,
    };
  },
  methods: {
    getList() {
      let that = this;
      that.tableLoading = true;
      that.axios
        .post("Base_Agent/GetList", {
          onlyText: that.companyName,
          currentPage: that.currentPage,
          pageSize: that.pageSize,
        })
        .then(function (response) {
          that.total = response.data.data.total;
          that.tableData = response.data.data.data;
          that.tableLoading = false;
        });
    },
    handleDel(row) {
      let that = this;
      that.$confirm("确定要删除吗？").then(function () {
        that.axios
          .post("Base_Agent/DelModel", { onlyInt: row.id })
          .then(function () {
            that.getList();
          });
      });
    },
  },
  mounted() {
    let that = this;
    that.getList();
  },
};
</script>

<style>
</style>