<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" title="编辑经销商">
    <el-form v-loading="formLoading" :model="form" ref="form" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="form.companyName">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="联系人" prop="contactName">
            <el-input v-model="form.contactName">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="openId" prop="openId">
            <el-input v-model="form.openId">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="省市" prop="provinceAndCity">
            <el-cascader :options="options" v-model="form.provinceAndCity" style="width:100%"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否审核">
            <el-switch v-model="form.isAudit">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注">
            <el-input v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="纬度">
            <el-input v-model.number="form.latitude" type="number">
            </el-input>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经度">
            <el-input v-model.number="form.longitude" type="number">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="updateModel">保存</el-button>
        <el-button @click="dialogVisible=false">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: { provinceAndCity: [] },
      options: null,
      formLoading: false,
      rules: {
        companyName: [{ required: true, message: "必填" }],
        phone: [{ required: true, message: "必填" }],
        contactName: [{ required: true, message: "必填" }],
        provinceAndCity: [
          { required: true, message: "必填", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    edit(row) {
      let that = this;
      that.dialogVisible = true;
      that.formLoading = true;
      that.initProvinceAndCity();
      that.axios.all([that.getModel(row.id)]).then(
        that.axios.spread(function (response1) {
          response1.data.data.provinceAndCity = [
            response1.data.data.provinceId,
            response1.data.data.cityId,
          ];
          that.form = response1.data.data;
          that.formLoading = false;
        })
      );
    },
    getModel(id) {
      let that = this;
      return that.axios.post("Base_Agent/GetModel", { onlyInt: id });
    },
    updateModel() {
      let that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.formLoading = true;
          that.form.provinceId = that.form.provinceAndCity[0];
          that.form.cityId = that.form.provinceAndCity[1];
          that.axios
            .post("Base_Agent/UpdateModel", that.form)
            .then(function () {
              that.formLoading = that.dialogVisible = false;
              that.$parent.getList();
            });
        } else return false;
      });
    },
    initProvinceAndCity() {
      let that = this;
      if (!that.options) {
        that.axios.post("Area/GetAllForElCascader").then(function (response) {
          that.options = response.data.data;
        });
      }
    },
  },
};
</script>

<style>
</style>